import * as React from "react"
import moment from 'moment';
import { get } from 'lodash';
import { PrismicRichText } from "@prismicio/react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import RecentPost from "./Recentpost"

import CustomerExperience from "../Elements/CustomerExperience"

const publishingDate = (x) => get(x, 'node.data.publishing_date');

const sortByPublishingDate = (x, y) => (
  moment(publishingDate(y)).valueOf() - moment(publishingDate(x)).valueOf()
);

const Blog = ({data, allPosts}) => {
  const { title, content, image, publishing_date, readtimeinminutes, author, category} = data.data
  return(
  <>
    <div className="bg-gradient xl:py-40 py-24">
      <div className="max-w-6xl mx-auto">
        <div className="md:grid md:grid-cols-2 grid-cols-1 md:gap-10 gap-6 items-center px-4 flex flex-col-reverse">
          <div className="xl:pr-12 w-100 blg-img">
            <GatsbyImage image={getImage(image)} alt={title && title.text} className="w-100 rounded-2xl" />
            {author && (
              <div className="flex gap-4 items-center xl:mt-16 my-8 md:hidden blg-author">            
                <img loading="lazy" src={author?.document?.data?.image && author.document.data.image.fixed.srcWebp} srcSet={author?.document?.data?.image && author.document.data.image.fixed.srcSetWebp} width={65} height={65}  />
                <div>
                  <p className="font-bold text-lg text-white mb-0">{author?.document?.data?.author_name && author.document.data.author_name.text}</p>
                  <p className="text-base text-white mb-0">{author?.document?.data?.designation && author.document.data.designation}</p>
                </div>
              </div>
            )}
            
            <div className="flex md:flex-row flex-col gap-4 md:items-center items-start justify-between mt-5">
            {category &&(
              <p className="text-blue font-medium bg-white rounded-full no-underline mb-0 py-0.5 px-2 text-sm text-center">{category?.document?.data?.name?.text}</p>
            )}
            <p className="text-white font-normal text-base m-0 mb-0">{moment(publishing_date).format('MMM D, YYYY')} {readtimeinminutes && (`- ${readtimeinminutes}min read`)}</p>
            </div>
          </div>
          <div>
            <h6 className="text-green-carriyo mb-3">ARTICLE</h6>
            <h1 className="xl:text-5xl md:text-4xl text-3xl text-white tracking-tighter md:mb-4 mb-2">{title && title.text}</h1>
            {author && (
               <div className="md:flex gap-4 items-center xl:mt-16 mt-8 hidden">
                <img loading="lazy" src={author?.document?.data?.image && author.document.data.image.fixed.srcWebp} srcSet={author?.document?.data?.image && author.document.data.image.fixed.srcSetWebp} width={65} height={65} className='mb-0'/>
                <div>
                  <p className="font-bold text-lg text-white mb-0">{author?.document?.data?.author_name && author.document.data.author_name.text}</p>
                  <p className="text-base text-white mb-0">{author?.document?.data?.designation && author.document.data.designation}</p>
                </div>
              </div>
            )}
           
          </div>
        </div>
      </div>
    </div>
    <div className="background1 ">
      <div className="relative xl:pt-32 xl:pb-20 md:pt-24 pt-16 pb-16 blog_content">
        <div className="blog-bg1 absolute right-0 md:-top-20 -top-16"></div>
        <div className="blog-bg2 absolute left-0 top-72 lg:block hidden"></div>
        <div className="blog-bg3 absolute right-48 top-2/4 lg:block hidden"></div>
        <div className="blog-bg1 blog-bg4 absolute left-0 bottom-1/4 lg:block hidden"></div>
        <div className="blog-bg2 blog-bg5 absolute right-0 lg:bottom-32 md:-bottom-20 -bottom-10"></div>
        <div className="max-w-2xl mx-auto px-4">
        <PrismicRichText
          field={content.richText}
          components={{
            oList: ({ children }) => (
              <ol style={{ listStyleType: 'decimal' }}>
                {children}
              </ol>
            ),
            list: ({ children }) => (
              <ul style={{ listStyleType: 'disc' }}>
                {children}
              </ul>
            ),
          }}
        />
       </div>
      </div>
      <div className="max-w-7xl mx-auto px-4">
        <div className="border-t border-blue-900 xl:py-20 py-12">
          <RecentPost allPosts={allPosts?.edges?.sort(sortByPublishingDate)} />
        </div>
      </div>
    </div>
    <div className="bg-gradient xl:py-40 md:py-24 py-16 text-white">
      <CustomerExperience items="clients" />
    </div>
  </>
)
}

export default Blog
