import React, { useState } from "react"
import { graphql } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Blog from '../components/Blog';


const BlogTemplate = (props) => {
  const { data, location } = props
  const { prismicPosts, allPosts } = data;

  if (!prismicPosts) return null;

  return (
    <Layout theme="dark">
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <Blog data={prismicPosts} allPosts={allPosts} />
    </Layout>
  );
};

export default BlogTemplate;

export const query = graphql`
  query BlogBySlug($uid: String!) {
    allPosts: allPrismicPosts {
      edges {
        node {
          uid
          id
          data {
            readtimeinminutes
            title {
              text
              html
            }
            short_description
            publishing_date
            author {
              document {
                ... on PrismicPostAuthors {
                  id
                  data {
                    author_name {
                      text
                      html
                    }
                    designation
                    image {
                      fixed(width: 65, height: 65) {
                        srcSetWebp
                        srcWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicPosts(uid: { eq: $uid }) {
      data {
        readtimeinminutes
        title {
          text
          html
        }
        content {
          text
          richText
          html
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        category {
          document {
            ... on PrismicPostsCategory {
              id
              data {
                name {
                  text
                  html
                }
              }
            }
          }
        }
        publishing_date
        author {
          document {
            ... on PrismicPostAuthors {
              id
              data {
                author_name {
                  text
                  html
                }
                designation
                image {
                  fixed(width: 65, height: 65) {
                    srcSetWebp
                    srcWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
